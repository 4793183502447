import { useMemo } from 'react';

interface Params {
  id?: string;
  overrideFactor?: unknown;
}

const useGeneratedId = ({ id, overrideFactor }: Params) => {
  return useMemo(() => {
    if (overrideFactor) {
      return id ?? `btn-generated-${Math.round(Math.random() * 10000)}`;
    }

    return id;
  }, [id, overrideFactor]);
};

export default useGeneratedId;
