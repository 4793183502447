import React, { FC, PropsWithChildren } from 'react';
import { t } from 'i18next';
import Lottie from 'lottie-react-web';

import useGeneratedId from '@root/hooks/useGeneratedId';

import { normalizeStringCompound } from '@utils/string';

import { ButtonProps } from '@root/interfaces/components/Button';
import { IconNames } from '@root/interfaces/components/Icon';
import { BrTooltipProps } from '@root/interfaces/components/BrTooltip';

import { Icon } from '@components/common';
import BrTooltip from '@components/common/BrTooltip';

import animation from './loading.json';

import './styles.scss';

const BASIC_CLASS = 'br-btn';

type BtnCmpType =
  | 'solid'
  | 'white'
  | 'text'
  | 'link'
  | 'black-link'
  | 'light'
  | 'yellow'
  | 'gray'
  | 'black'
  | 'red'
  | 'red-text'
  | 'purple';

export interface BrButtonProps extends Omit<ButtonProps, 'size'> {
  size?: 'xSmall' | 'small' | 'middle' | 'default';
  cmpType?: BtnCmpType;
  state?: 'default' | 'tapped' | 'disabled' | 'loading';
  cmpStyle?: 'default' | 'circle';
  hasIconRight?: boolean;
  hasIconLeft?: boolean;
  iconName?: IconNames;
  text?: string;
  tooltipCfg?: BrTooltipProps;
}

const BtnIcon: React.FC<{ iconName: IconNames; isCircle?: boolean }> = ({
  iconName,
  isCircle,
}) => {
  return (
    <Icon
      className="btn-icon"
      name={iconName}
      width={isCircle ? 20 : undefined}
      height={isCircle ? 20 : undefined}
    />
  );
};

const BrButton: FC<PropsWithChildren<BrButtonProps>> = (props) => {
  const {
    id,
    children,
    className,
    onClick,
    shape,
    size,
    disabled,
    type = 'button',
    style,
    dataTestId,
    state,
    cmpType,
    cmpStyle,
    hasIconLeft,
    hasIconRight,
    iconName,
    text,
    tooltipCfg,
    ...rest
  } = props;

  const idOverridden = useGeneratedId({
    id,
    overrideFactor: tooltipCfg,
  });

  const classNames = normalizeStringCompound([
    BASIC_CLASS,
    `${BASIC_CLASS}-${shape}`,
    `${BASIC_CLASS}-${size}`,
    state === 'default' ? undefined : `${BASIC_CLASS}-${state}`,
    `${BASIC_CLASS}-${cmpType}`,
    cmpStyle === 'default' ? undefined : `${BASIC_CLASS}-${cmpStyle}`,
    className,
  ]);

  const isLoading = state === 'loading';

  return (
    <>
      <button
        id={idOverridden}
        type={type}
        disabled={disabled || isLoading}
        className={classNames}
        onClick={onClick}
        style={style}
        data-test-id={dataTestId}
        {...rest}
      >
        {isLoading && (
          <div>
            <Lottie
              options={{ animationData: animation }}
              ariaRole="img"
              height={16}
              width={16}
            />
          </div>
        )}

        {cmpStyle === 'circle' && iconName ? (
          <BtnIcon iconName={iconName} isCircle />
        ) : (
          <>
            {!isLoading && hasIconLeft && iconName && <BtnIcon iconName={iconName} />}
            {text && <span>{isLoading ? t('Please wait...') : text}</span>}
            {children && <span>{children}</span>}
            {!isLoading && hasIconRight && iconName && <BtnIcon iconName={iconName} />}
          </>
        )}
      </button>
      {/* TODO: think on how to move it outside of this component */}
      {disabled && tooltipCfg && idOverridden && (
        <BrTooltip {...tooltipCfg} anchorSelect={`#${idOverridden}`} />
      )}
    </>
  );
};

BrButton.defaultProps = {
  shape: 'round',
  size: 'default',
  color: 'blue',
  type: 'button',
  cmpType: 'solid',
  state: 'default',
  cmpStyle: 'default',
};

export default BrButton;
