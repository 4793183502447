import React, { FC } from 'react';
import { useToaster } from 'react-hot-toast';

import { useIsDesktop } from '@components/common/MediaQueryMatchers';

import BrToast from './BrToast';

const DURATION = 3000; // ms

const BrToaster: FC<React.PropsWithChildren<unknown>> = () => {
  const { toasts } = useToaster({ duration: DURATION });

  const isDesktop = useIsDesktop();

  const brToasts = toasts
    .filter((item) => {
      return item.className !== undefined && item.visible;
    })
    .map((item) => {
      return <BrToast key={item.id} {...item} />;
    });

  if (!brToasts.length) {
    // anti-pattern
    return null;
  }

  return isDesktop ? (
    <div className="fixed z-[11112] top-[48px] left-1/2 transform -translate-x-1/2">
      <div className="flex flex-col-reverse ml-[var(--br-sidebar-width)]">{brToasts}</div>
    </div>
  ) : (
    <div className="fixed z-[11112] bottom-[48px] left-1/2 transform -translate-x-1/2">
      <div className="flex flex-col-reverse">{brToasts}</div>
    </div>
  );
};

export default BrToaster;
