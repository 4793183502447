import { BrCheckboxProps } from '@root/interfaces/components/BrCheckbox';
import { BrTooltipProps } from '@root/interfaces/components/BrTooltip';

import BrTooltip from '@components/common/BrTooltip';

import useGeneratedId from '@root/hooks/useGeneratedId';

import BrToggle from '../BrToggle';
import Icon from '../Icon';

type Props = BrCheckboxProps & {
  leftText: string;
  tooltipCfg?: BrTooltipProps;
  onInfoIconClick?(): void;
};

const BrAutoRechargeToggle = (props: Props) => {
  const { leftText, tooltipCfg, onInfoIconClick, ...toggleProps } = props;

  const idOverridden = useGeneratedId({
    overrideFactor: tooltipCfg,
  });

  return (
    <div className="flex items-center">
      {/* TODO: show tooltip ("Never get off in the middle of a call...") according to the design when clicking/hovering over the left info icon, this TODO depends on https://idtjira.atlassian.net/browse/BMP-887 */}
      {/* this should look approximately like:
        <BrTooltip text={infoText} position="auto">
          <Icon width={14} height={14} name="information-circle-outline" />
        </BrTooltip>
      */}
      <Icon
        id={idOverridden}
        onClick={onInfoIconClick}
        width={18}
        height={18}
        name="information-circle-outline"
      />
      {idOverridden && tooltipCfg && (
        <BrTooltip {...tooltipCfg} anchorSelect={`#${idOverridden}`} />
      )}
      <div className="text-body/caption/medium ml-xsmall">{leftText}</div>
      <BrToggle className="ml-small" {...toggleProps} />
    </div>
  );
};

export default BrAutoRechargeToggle;
