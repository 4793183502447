/* eslint-disable @typescript-eslint/indent */
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import InputMask from 'react-input-mask';

import Yup from '@utils/validation/yup';
import { normalizeStringCompound } from '@utils/string';

import useModal from '@root/hooks/useModal';

import CountryCode from '@root/interfaces/CountryCode';

import { CVV_INPUT_MASK } from '@root/constants/inputMasks';

import { getZipPostalCodeValidationFn } from '../PaymentCardBillingAddressForm/helpers';
import BrAdvancedInput from '../BrAdvancedInput';

import {
  ZIP_POSTAL_MAX_LENGTH,
  zipFieldLabelDefault,
  zipFieldLableMap,
  zipFieldMaskDefault,
  zipFieldMaskMap,
  zipFieldTypeDefault,
  zipFieldTypeMap,
  zipFiledInputModeDefault,
  zipFiledInputModeMap,
} from '../PaymentCardBillingAddressForm/constants';

import BrCard from '../BrCard';
import BrButton from '../BrButton';
import Spinner from '../Spinner';
import BrModal from '../BrModal';
import BrCvvModalContent from './components/BrCvvModalContent';

import { useIsMobile } from '../MediaQueryMatchers';

export interface BrPaymentCardReducedDetailsFormValues {
  cardNumber: string;
  expDateString: string;
  cvv: string;
  zip: string;
}

interface FormProps<T> {
  dataTestPrefixId: string;
  className?: string;
  handleId?: string;
  submitBtnText: string;
  isEdit?: boolean;
  shouldValidateOnMount?: boolean;
  isLoading?: boolean;
  hasCancelBtn?: boolean;
  values: T;
  countryCode: CountryCode;
  onSubmit(values: T, actions?: FormikHelpers<T>): Promise<void> | void;
  onClose?(): void;
  onRemove(): void;
  extraValidation?: {
    conditionFunc(): boolean;
    errorText: string;
  };
}

const EXP_DATE_MASK = 'MM / yy';

const BrPaymentCardReducedDetailsForm = (
  props: FormProps<BrPaymentCardReducedDetailsFormValues>,
) => {
  const {
    isEdit,
    className,
    countryCode,
    submitBtnText,
    onSubmit,
    values,
    onClose,
    isLoading,
    onRemove,
    hasCancelBtn,
    extraValidation,
  } = props;

  const { t } = useTranslation();

  const {
    modalHeader,
    modalContent,
    setModalHeader,
    setModalContent,
    isModalOpen,
    showModal,
    closeModal,
  } = useModal();

  const isMobile = useIsMobile();

  const showCvvModal = () => {
    setModalHeader(t('What is a CVV?'));
    setModalContent(<BrCvvModalContent onClose={closeModal} />);
    showModal();
  };

  const validationSchema = {
    cardNumber: Yup.lazy(() => {
      return isEdit ? Yup.string() : Yup.string().cardNumber().required();
    }),
    expDateString: Yup.string().expirationDateString().required(),
    zip: getZipPostalCodeValidationFn(countryCode),
    cvv: Yup.lazy(() => {
      return isEdit ? Yup.string() : Yup.string().cvv().required();
    }),
  };

  const ZipField = () => {
    const { getFieldProps, getFieldMeta } = useFormikContext();

    return (
      <InputMask
        type={zipFieldTypeMap[countryCode] || zipFieldTypeDefault}
        mask={zipFieldMaskMap[countryCode] || zipFieldMaskDefault}
        maskChar=""
        inputMode={zipFiledInputModeMap[countryCode] || zipFiledInputModeDefault}
        required
        {...getFieldProps('zip')}
      >
        {() => (
          <BrAdvancedInput
            onWhat="surface"
            required
            topLabel={zipFieldLableMap[countryCode] || zipFieldLabelDefault}
            className="flex-grow ml-0"
            name="zip"
            id="zip"
            maxLength={ZIP_POSTAL_MAX_LENGTH}
            placeholder={t('Enter {{zip-post-code}}', {
              'zip-post-code': zipFieldLableMap[countryCode] || zipFieldLabelDefault,
            })}
            hasError={Boolean(getFieldMeta('zip').error && getFieldMeta('zip').touched)}
            errorText={getFieldMeta('zip').error}
          />
        )}
      </InputMask>
    );
  };

  return (
    <>
      <Spinner isSpinning={Boolean(isLoading)}>
        <Formik
          initialValues={values}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={Yup.object().shape(validationSchema)}
        >
          {(formik) => {
            const isSubmitBtnDisabled = extraValidation
              ? !formik.isValid || !extraValidation.conditionFunc()
              : !formik.isValid;

            return (
              <BrCard className={normalizeStringCompound([className, 'overflow-hidden'])}>
                <div className="flex mb-default">
                  <div className="text-body/callout/default mr-auto">
                    {isEdit ? t('Edit payment card') : t('Add payment card')}
                  </div>

                  {hasCancelBtn && (
                    <BrButton onClick={onClose} text={t('Cancel')} cmpType="link" />
                  )}
                </div>
                <Form>
                  <div className="space-y-middle">
                    <InputMask
                      mask={isEdit ? '*****' : '9999 9999 9999 9999'}
                      maskChar={isEdit ? '' : ''}
                      inputMode="numeric"
                      required
                      {...formik.getFieldProps('cardNumber')}
                    >
                      {() => (
                        <BrAdvancedInput
                          onWhat="surface"
                          required
                          topLabel={t('Card number')}
                          className="flex-grow"
                          name="cardNumber"
                          id="cardNumber"
                          placeholder={t('Enter card number')}
                          isDisabled={isEdit}
                          hasError={Boolean(
                            formik.getFieldMeta('cardNumber').error &&
                              formik.getFieldMeta('cardNumber').touched,
                          )}
                          errorText={formik.getFieldMeta('cardNumber').error}
                        />
                      )}
                    </InputMask>
                    <div className="flex space-x-middle w-full">
                      <InputMask
                        mask={EXP_DATE_MASK.replace(/[a-z]/gi, '9')}
                        maskChar=""
                        inputMode="numeric"
                        {...formik.getFieldProps('expDateString')}
                      >
                        {() => (
                          <BrAdvancedInput
                            onWhat="surface"
                            required
                            topLabel={t('Expiration date')}
                            className="flex-grow basis-1/2"
                            name="expDateString"
                            id="expDateString"
                            placeholder={t('MM / YY')}
                            hasError={Boolean(
                              formik.getFieldMeta('expDateString').error &&
                                formik.getFieldMeta('expDateString').touched,
                            )}
                            errorText={formik.getFieldMeta('expDateString').error}
                          />
                        )}
                      </InputMask>
                      {isEdit ? (
                        <div className="flex-grow basis-1/2">
                          <ZipField />
                        </div>
                      ) : (
                        <InputMask
                          mask={CVV_INPUT_MASK}
                          maskChar=""
                          inputMode="numeric"
                          {...formik.getFieldProps('cvv')}
                        >
                          {() => (
                            <BrAdvancedInput
                              onWhat="surface"
                              required
                              topLabel={t('CVV')}
                              className="flex-grow basis-1/2"
                              name="cvv"
                              id="cvv"
                              type="password"
                              iconRight="information-circle-outline"
                              placeholder={isMobile ? t('CVV') : t('Enter CVV')}
                              hasError={Boolean(
                                formik.getFieldMeta('cvv').error &&
                                  formik.getFieldMeta('cvv').touched,
                              )}
                              errorText={formik.getFieldMeta('cvv').error}
                              onIconClickRight={showCvvModal}
                            />
                          )}
                        </InputMask>
                      )}
                    </div>
                    {!isEdit && <ZipField />}
                  </div>
                  <div className="mt-default">
                    <BrButton
                      className="w-full"
                      type="submit"
                      text={submitBtnText}
                      disabled={isSubmitBtnDisabled}
                      tooltipCfg={{
                        place: 'top',
                        content:
                          formik.dirty && formik.isValid
                            ? extraValidation?.errorText
                            : t('Please verify the fields'),
                      }}
                    />
                    {isEdit && (
                      <BrButton
                        className="w-full mt-small"
                        cmpType="red-text"
                        text={t('Delete card')}
                        // TODO: handleId || '1'
                        onClick={onRemove}
                      />
                    )}
                  </div>
                </Form>
              </BrCard>
            );
          }}
        </Formik>
      </Spinner>
      {/* CVV Modal */}
      <BrModal
        header={modalHeader}
        isOpen={isModalOpen}
        onClose={closeModal}
        hasCloseButton
      >
        {modalContent}
      </BrModal>
    </>
  );
};

export default BrPaymentCardReducedDetailsForm;
